export default () => {
  const { doFetch } = useFetchApi()
  const { messageConfirm, messageSuccess } = useSwal()
  const { t } = useNuxtApp().$i18n

  const addBlock = async (body) => {
    return await doFetch({
      type: 'block',
      method: 'POST',
      body,
    })
  }

  const deleteBlock = async (id) => {
    return await doFetch({
      type: 'blockId',
      method: 'DELETE',
      input: id,
    })
  }

  const doBlock = async (type = '', target = '', name = '') => {
    if (!target || !type || !name) return false

    const { value } = await messageConfirm(t('block.title'), `${t('block.target')}: "${name}" ${t('block.blockInfo')}`)
    if (value) {
      const res = await addBlock({ target, type })
      if (res.id) {
        await messageSuccess(t('ajax.success'), t('block.success'))
        return true
      }
    }
  }

  const doUnblock = async (id = '', name = '') => {
    if (!id || !name) return false

    const { value } = await messageConfirm(t('block.unblockTitle'), `${t('block.target')}: "${name}" ${t('block.unblockInfo')}`)
    if (value) {
      const res = await deleteBlock(id)
      if (res.delete) {
        await messageSuccess(t('ajax.success'))
        return true
      }
    }
  }
  return {
    doBlock,
    doUnblock,
  }
}
